import React, { useState, useLayoutEffect } from "react";
import { Layout, Card, Row, Col, message } from 'antd';
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

import { success, error } from "../../helpers/toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const HomePage = () => {

    let navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [data, setDatas] = useState(null);
    const [refresh, setRefresh] = useState(false);

    function handleNavigation(event, key) {
        event.preventDefault();
        navigate(`/${key}`);
    }

    useLayoutEffect(() => {
      
    }, [refresh]);
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Row gutter={[16]} style={{ marginBottom: 5 }}>
                <Col xs={48} sm={24} md={16} lg={24}>
                    <Card
                        title={
                            <div>
                                <InfoCircleOutlined style={{ marginLeft: '8px', fontSize: '16px', marginRight: 5 }} />
                                {t('NOTIFICATION')}
                            </div>
                        }
                        bordered={true}
                        style={{ fontWeight: "bold" }}
                    >
                       
                       
                       
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};

export default HomePage;
