import React, { useState, useLayoutEffect } from "react";
import { Layout, Card, Row, Col, message, Button, Upload, Space, Table, Tag } from 'antd';
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

import { success, error } from "../../helpers/toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addCertificas, addFile, addLogo, getCertificates, getLogos, sendMail } from "../../store/slices/certificate";

const LogosPage = () => {

    let navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [data, setDatas] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    const [fileList, setFileList] = useState([]);

    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            console.log(file)
            return false;
        },
        fileList,
    };

    const handleUpload = () => {
        const formData = new FormData();
        formData.append('File', fileList[0]);

        dispatch(addLogo(formData))
            .unwrap()
            .then((result) => {
                dispatch(getLogos())
                    .unwrap()
                    .then((result) => {
                        setDatas(result)
                    })

            })

    };





    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRowKeys);
            setSelectedRowKeys(selectedRowKeys)
        }
    };

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'url',
            dataIndex: 'url',
            key: 'url',
        },



        // {
        //     title: 'Action',
        //     key: 'action',
        //     render: (_, record) => (
        //         <Space size="middle">
        //             <a href="#" onClick={() => handleClick(record)}>
        //                 Git
        //             </a>
        //         </Space>
        //     ),
        // },
    ];
    useLayoutEffect(() => {
        dispatch(getLogos())
            .unwrap()
            .then((result) => {
                setDatas(result)
            })

    }, [refresh]);



    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Row style={{ marginBottom: 10 }}>
                <Col xs={48} sm={24} md={16} lg={24}>
                    <Upload maxCount={1} {...props}>
                        <Button icon={<UploadOutlined />}>Dosya Seç</Button>
                    </Upload>
                    <Button
                        type="primary"
                        onClick={handleUpload}
                        disabled={fileList.length === 0}
                        style={{
                            marginTop: 16,
                        }}
                    > Dosyayı Yükle</Button>

                </Col>
            </Row>
            <Row gutter={[24]} style={{ marginBottom: 5 }}>
                <Col xs={48} sm={24} md={16} lg={24}>

                    <Table
                        rowKey={(record) => record.id}
                        rowSelection={{
                            type: "checkbox",
                            ...rowSelection,
                        }} columns={columns} dataSource={data} />

                </Col>
            </Row>
        </Layout>
    );
};

export default LogosPage;
