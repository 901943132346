import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd'
import tr_TR from 'antd/lib/locale/tr_TR';
import { BrowserRouter } from "react-router-dom";
import store from './store/configureStore'
import dayjs from "dayjs";
import "dayjs/locale/tr";
import updateLocale from "dayjs/plugin/updateLocale";
import { Provider } from 'react-redux'
import './localization'


const root = ReactDOM.createRoot(document.getElementById('root'));
dayjs.extend(updateLocale);
dayjs.updateLocale("tr", {
  weekStart: 1
});
root.render(


  <ConfigProvider locale={tr_TR}
    theme={{
      token: {
        // Seed Token
        colorPrimary: '#109047',
        fontFamily: 'ubuntu',

      },
      components: {
        Title: {
          fontFamily: 'ubuntu',
        },
        Paragraph: {
          fontFamily: 'ubuntu',
        }

      }
    }}>
    <Provider store={store}>
      <BrowserRouter >
        <App />
      </BrowserRouter>
    </Provider>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
