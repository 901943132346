import { Navigate, useRoutes } from 'react-router-dom';

// routes
import LoginRoutes from './LoginRoutes.js';
import MainRoutes from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([LoginRoutes, MainRoutes,
        // "catch-all" route
        {
            path: '*',
            element: <NotFoundHandler />,
        },]);
}


function NotFoundHandler() {
    console.error('Hata: Eşleşen bir yol bulunamadı. Loglama veya başka bir işlem yapılabilir.');
    // Loglama işlemlerini burada gerçekleştirebilir veya istediğiniz başka bir işlemi yapabilirsiniz.

    // Örneğin, kullanıcıyı bir hata sayfasına yönlendirebilirsiniz.
    return <Navigate to="/Home" />;
}