// project imports
import MainLayout from '../components/layout';
import AuthGuard from '../utils/AuthGuard';

// sample page routing

import HomePage from '../views/home';
import ImportFilePage from '../views/certificate/importFile';
import CertificatesPage from '../views/certificate/certificates';
import LogosPage from '../views/logo';


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/Home',
      element: <HomePage/>
    }, 
    {
      path: '/Import',
      element: <ImportFilePage/>
    }, 
    {
      path: '/Certificates',
      element: <CertificatesPage/>
    }, 
    {
      path: '/Logos',
      element: <LogosPage/>
    },
    
  ]
};

export default MainRoutes;