import React, { useState, useLayoutEffect } from "react";
import { Layout, Card, Row, Col, message, Button, Upload, Space, Table, Tag } from 'antd';
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

import { success, error } from "../../helpers/toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addCertificas, addFile } from "../../store/slices/certificate";

const ImportFilePage = () => {

    let navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [data, setDatas] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [fileList, setFileList] = useState([]);

    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            console.log(file)
            return false;
        },
        fileList,
    };

    function handleNavigation(event, key) {
        event.preventDefault();
        navigate(`/${key}`);
    }

    const handleUpload = () => {
        const formData = new FormData();
        formData.append('File', fileList[0]);

        dispatch(addFile(formData))
            .unwrap()
            .then((result) => {
                setDatas(result)
                
                console.log(result)
            })

    };

    const handleUploadCertifica = () => {
        

        dispatch(addCertificas(data))
            .unwrap()
            .then((result) => {

                success("Başarılı",message)
                navigate("/Certificates")
            })

    };


    const columns = [
        {
            title: 'certificateType',
            dataIndex: 'certificateType',
            key: 'certificateType',
        },
        {
            title: 'logo',
            dataIndex: 'logo',
            key: 'logo',
        },
        {
            title: 'logoTwo',
            dataIndex: 'logoTwo',
            key: 'logoTwo',
        },
        {
            title: 'date',
            dataIndex: 'date',
            key: 'date',
        }, 
        {
            title: 'programName',
            dataIndex: 'programName',
            key: 'programName',
        }, 
        {
            title: 'signature',
            dataIndex: 'signature',
            key: 'signature',
        }, 
        {
            title: 'studentName',
            dataIndex: 'studentName',
            key: 'studentName',
        }, 
        {
            title: 'studentMail',
            dataIndex: 'studentMail',
            key: 'studentMail',
        },

        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a>Delete</a>
                </Space>
            ),
        },
    ];
    useLayoutEffect(() => {

    }, [refresh]);
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Row gutter={[24]} style={{ marginBottom: 5 }}>
                <Col xs={48} sm={24} md={16} lg={24}>
                    <Upload maxCount={1} {...props}>
                        <Button icon={<UploadOutlined />}>Dosya Seç</Button>
                    </Upload>
                    <Button
                        type="primary"
                        onClick={handleUpload}
                        disabled={fileList.length === 0}
                        style={{
                            marginTop: 16,
                        }}
                    > Dosyayı Yükle</Button>

                </Col>
                <Col xs={48} sm={24} md={16} lg={24}>

                <Button
                        type="primary"
                        onClick={handleUploadCertifica}
                        disabled={data.length === 0}
                        style={{
                            marginTop: 16,
                        }}
                    > Sertifikaları Yükle</Button>

                    <Table columns={columns} dataSource={data} />

                </Col>
            </Row>
        </Layout>
    );
};

export default ImportFilePage;
