import { lazy } from 'react';


import CertificateTwoPage from '../views/certificate/certificateTwo/certificateTwoPage';

// project imports

// login routing
const AuthLogin = lazy(() => import('../views/login'));

// ==============================|| AUTH ROUTING ||============================== //


const LoginRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: <AuthLogin />
        },
        {
            path: '/CertificateTwo',
            element: <CertificateTwoPage />
        }


    ]
};

export default LoginRoutes;