import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import { useSelector } from 'react-redux';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const isLoggedIn = useSelector((state) => state.auth.isLogged);
    const navigate = useNavigate();
    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/', { replace: false });
        }
    }, [isLoggedIn, navigate]);

    return children;
};


export default AuthGuard;