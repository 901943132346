import React, { useState, useLayoutEffect } from "react";
import { Layout, Card, Row, Col, message, Button, Upload, Space, Table, Tag } from 'antd';
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

import { success, error } from "../../helpers/toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addCertificas, addFile, getCertificates, sendMail } from "../../store/slices/certificate";

const CertificatesPage = () => {

    let navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [data, setDatas] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([])


    const handleClick = (record) => {
        window.open(`/CertificateTwo?id=${record.id}`, '_blank');
    };



    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRowKeys);
            setSelectedRowKeys(selectedRowKeys)
        }
    };

    const columns = [
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a href="#" onClick={() => handleClick(record)}>
                        Git
                    </a>
                </Space>
            ),
        },
        {
            title: 'Mail',
            dataIndex: 'mailSend',
            key: 'mailSend',
            
              render: (_, record) => (
                <Tag color={record.mailSend ? 'green' : 'volcano'} key={record.id}>
                        {record.mailSend?"Gönderildi":"Gönderilmedi"}
                      </Tag>
            ),
        },
        {
            title: 'certificateType',
            dataIndex: 'certificateType',
            key: 'certificateType',
        },
        {
            title: 'logo',
            dataIndex: 'logo',
            key: 'logo',
        },
        {
            title: 'logoTwo',
            dataIndex: 'logoTwo',
            key: 'logoTwo',
        },
        {
            title: 'date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'programName',
            dataIndex: 'programName',
            key: 'programName',
        },
        {
            title: 'signature',
            dataIndex: 'signature',
            key: 'signature',
        },
        {
            title: 'studentName',
            dataIndex: 'studentName',
            key: 'studentName',
        },
        {
            title: 'studentMail',
            dataIndex: 'studentMail',
            key: 'studentMail',
        },

       
    ];
    useLayoutEffect(() => {
        dispatch(getCertificates())
            .unwrap()
            .then((result) => {
                setDatas(result)
            })

    }, [refresh]);

    const sendMails = () => {
        let body = selectedRowKeys.map(x => x)
        console.log(body)
        dispatch(sendMail(body))
            .unwrap()
            .then((result) => {
                success("Başarılı", message)
            })
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Row style={{ marginBottom: 10 }}>
                <Button onClick={() => sendMails()} type="primary" style={{ width: '25%' }}>Seçilenlere Mail Gönder</Button>
            </Row>
            <Row gutter={[24]} style={{ marginBottom: 5 }}>
                <Col xs={48} sm={24} md={16} lg={24}>

                    <Table
                        rowKey={(record) => record.id}
                        rowSelection={{
                            type: "checkbox",
                            ...rowSelection,
                        }} columns={columns} dataSource={data} />

                </Col>
            </Row>
        </Layout>
    );
};

export default CertificatesPage;
