import { Config } from "../utils/config";
export const Endpoints = {
  IDENTITY: {
    TOKEN: Config.API_URL + "/Auth/Get-Token",
    GET_USER_INFO: Config.API_URL + "/Auth/User-Info",
  
  },
  CERTIFICATE: {
    FILE: Config.API_URL + "/Certificate/File",
    POST:Config.API_URL + "/Certificate/List",
    GET:Config.API_URL + "/Certificate",
    MAIL:Config.API_URL + "/Certificate/Mail",
    GET_LOGO:Config.API_URL + "/Certificate/Logo",
    POST_LOGO:Config.API_URL + "/Certificate/Logo",

  
  },
};
