import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../../helpers/client";
import { Endpoints } from "../../../utils/Endpoints";
import { act } from "react-dom/test-utils";

const initialState = {
 
  loading: false,
 
};

export const addFile = createAsyncThunk("addFile", async (body) => {
  const response = await client.fileUpload(Endpoints.CERTIFICATE.FILE, body,{
    
  });
  return response.data;
});

export const addCertificas = createAsyncThunk("addCertificas", async (body) => {
    const response = await client.post(Endpoints.CERTIFICATE.POST, body,{
      
    });
    return response.data;
  });

  export const getCertificates = createAsyncThunk("getCertificates", async (body) => {
    const response = await client.get(Endpoints.CERTIFICATE.GET, body,{
      
    });
    return response.data;
  });

  export const getLogos = createAsyncThunk("getLogos", async (_) => {
    const response = await client.get(Endpoints.CERTIFICATE.GET_LOGO,{
      
    });
    return response.data;
  });

  export const getCertificate = createAsyncThunk("getCertificate", async (id) => {
    const response = await client.get(Endpoints.CERTIFICATE.GET+"/id?id="+id,{
      
    });
    return response.data;
  });

  export const sendMail = createAsyncThunk("sendMail", async (body) => {
    const response = await client.post(Endpoints.CERTIFICATE.MAIL, body,{
      
    });
    return response.data;
  });

  export const addLogo = createAsyncThunk("addLogo", async (body) => {
    const response = await client.fileUpload(Endpoints.CERTIFICATE.POST_LOGO, body,{
      
    });
    return response.data;
  });


const certificateSlice = createSlice({
  name: "certificate",
  initialState,
  reducers: {
   
  },
  extraReducers(builder) {
    builder.addCase(addFile.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addFile.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addFile.rejected, (state, action) => {
      state.loading = false;
    });

  
    

    
  },
});

export const {  } =
certificateSlice.actions;
export default certificateSlice.reducer;
