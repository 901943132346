// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper


export async function client(endpoint, { body, ...customConfig } = {}) {
  const headers = {}
  if (customConfig.token)
    headers.Authorization = `Bearer ${customConfig.token}`

  if (customConfig.masstransitMessage)
    headers.Message = JSON.stringify(customConfig.masstransitMessage);


  const config = {
    method: customConfig.method,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };


  if (body && customConfig.customBody === undefined) {
    config.body = config.isFile === undefined ? JSON.stringify(body) : body;
  }

  if (customConfig.customBody) {
    config.body = customConfig.customBody
  }
  let data
  try {
    const response = await window.fetch(endpoint, config)

    if (response.ok) {
     
        data = await response.json().catch((e) => console.log(e));
      // Return a result object similar to Axios
      return {
        status: response.status,
        data,
        headers: response.headers,
        url: response.url,
      }
    }
    throw new Error(response.status)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data)
  }
}

client.fileUpload = function (endpoint, body, customConfig = {}) {
  customConfig.isFile = true;
  return client(endpoint, {
    ...customConfig,
    headers: {
    },
    method: 'POST',
    body,
  });
};

client.filePut = function (endpoint, body, customConfig = {}) {
  customConfig.isFile = true;
  return client(endpoint, {
    ...customConfig,
    headers: {
    },
    method: 'PUT',
    body,
  });
};

client.get = function (endpoint, customConfig = {}) {
  return client(endpoint, {
    ...customConfig, headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    }, method: 'GET'
  })
}

client.post = function (endpoint, body, customConfig = {}) {

  return client(endpoint, {
    ...customConfig, headers: customConfig.headers === undefined ? {
      Accept: "*/*",
      "Content-Type": "application/json",
      Connection: "keep-alive",
      "Accept-Encoding": "gzip,deflate,br",
      "Access-Control-Allow-Origin": "*",
    } : customConfig.headers, method: 'POST', body
  })
}

client.put = function (endpoint, body, customConfig = {}) {
  return client(endpoint, {
    ...customConfig, headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Connection: "keep-alive",
      "Accept-Encoding": "gzip,deflate,br",
      "Access-Control-Allow-Origin": "*",
    }, method: 'PUT', body
  })
}

client.upload = function (endpoint, body, customConfig = {}) {
  customConfig.isFile = false;
  return client(endpoint, {
    ...customConfig,
    headers: {
    },
    method: 'POST',
    body,
  });
};

client.delete = function (endpoint, body, customConfig = {}) {
  return client(endpoint, {
    ...customConfig, headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Connection: "keep-alive",
      "Accept-Encoding": "gzip,deflate,br",
      "Access-Control-Allow-Origin": "*",
    }, method: 'DELETE', body
  })
}
