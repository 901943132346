import Moment from "moment";
import * as XLSX from "xlsx";
import {
        HomeFilled,
        LogoutOutlined,
        UserOutlined,
        FundViewOutlined,
        FileOutlined,
        StarOutlined,
        FundOutlined,
        KeyOutlined
      } from "@ant-design/icons";

//Gönderilen Tarih-Saat formatını gün ay ve yıl olarak düzenler.
export const dateTemplate = (value) => {
        return (Moment(value).format('DD.MM.YYYY'));
}

export const filter = (data, columnName) => {
        if (data === null)
                return null;
        let result = [];
        if (columnName.split('.').length > 1) {
                data.map(item => {
                        var isExist = result.filter(x => x.text === item[columnName.split('.')[0]][columnName.split('.')[1]] && x.value === item[columnName.split('.')[0]][columnName.split('.')[1]]);
                        if (isExist.length === 0)
                                result.push({
                                        text: item[columnName.split('.')[0]][columnName.split('.')[1]],
                                        value: item[columnName.split('.')[0]][columnName.split('.')[1]]
                                })
                })

        } else {
                data.map(item => {
                        var isExist = result.filter(x => x.text === item[columnName] && x.value === item[columnName]);
                        if (isExist.length === 0)
                                result.push({
                                        text: item[columnName],
                                        value: item[columnName]
                                })
                })

        }
        // eslint-disable-next-line array-callback-return

        return result
}

export const dateFilter = (data, columnName) => {
        let result = [];
        // eslint-disable-next-line array-callback-return
        data.map(item => {
                var isExist = result.filter(x => x.text === dateTemplate(item[columnName]) && x.value === dateTemplate(item[columnName]));
                if (isExist.length === 0)
                        result.push({
                                text: dateTemplate(item[columnName]),
                                value: dateTemplate(item[columnName])
                        })
        })
        return result
}

export const textSorter = (a, b, dataIndex) => {
        const x = a[dataIndex] || "";
        const y = b[dataIndex] || "";
        return x.localeCompare(y);
};

export const numberSorter = (a, b, dataIndex) => {
        return a[dataIndex] - b[dataIndex];
};

export const boolSorter = (a, b, dataIndex) => {
        const x = Boolean(a[dataIndex]);
        const y = Boolean(b[dataIndex]);

        if (x === y) {
                return 0;
        } else if (x) {
                return 1;
        } else {
                return -1;
        }
};

export const formatDate = (isoString) => {
        if (!isoString) return null;
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
};

export const exportToExcel = ({ columns, datas, name }) => {
        const headers = columns.map((column) => column.title);

        const data = datas.map((item) => {
                const rowData = {};
                columns.forEach((column) => {
                        if (Array.isArray(column.dataIndex)) {
                                let tempData = item;
                                column.dataIndex.forEach((index) => {
                                        if (tempData && typeof tempData === 'object' && index in tempData) {
                                                tempData = tempData[index];
                                        } else {
                                                tempData = column.render ? column.render(item) : '';
                                        }
                                });
                                rowData[column.title] = tempData;
                        } else {
                                rowData[column.title] = item[column.dataIndex];
                        }
                });
                return rowData;
        });

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data, { header: headers });
        XLSX.utils.book_append_sheet(workbook, worksheet, name);
        XLSX.writeFile(workbook, `${name}.xlsx`);
};
export const commonFilter = (value, record, dataIndex) => {
        var splitted = dataIndex.split('.')
        if (splitted.length == 1)
                return record[dataIndex]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase());
        else {

                var currentValue = record;
                for (var i = 0; i < splitted.length; i++) {
                        currentValue = currentValue[splitted[i]]; // Her bir özelliğe adım adım erişim
                }
                return currentValue.toString().toLowerCase().includes(value.toLowerCase());
        }

};

export const getAntDesignIcon = (icon) => {
        // Ant Design'de kullanılan ikonları bir şekilde eşleştirin
        const iconMappings = {
                HomeFilled: <HomeFilled />,
                LogoutOutlined: <LogoutOutlined />,
                UserOutlined: <UserOutlined />,
                FundViewOutlined: <FundViewOutlined />,
                FileOutlined: <FileOutlined />,
                StarOutlined: <StarOutlined />,
                FundOutlined: <FundOutlined />,
                KeyOutlined: <KeyOutlined />,
         
          // Diğer ikon eşleştirmeleri
        };
        return iconMappings[icon] || <HomeFilled />; // Belirtilen bir eşleşme yoksa varsayılan ikon kullanılır
      };