import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: '',
  isLogged: false,
  loading: false,
  user: {}
};


const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, action) {
      if (action.payload)
        state.isLogged = true;
      else
        state.isLogged = false
      
      state.token = action.payload
    },
    setUser(state, action) {
      state.user = action.payload
    }
  },
  extraReducers(builder) {

  },
});

export const { setToken, setUser } = authSlice.actions;
export default authSlice.reducer;
