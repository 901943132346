import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../../helpers/client";
import { Endpoints } from "../../../utils/Endpoints";
import { act } from "react-dom/test-utils";

const initialState = {
  token: "",
  userInfo: {},
  loading: false,
  authorizedScreens: {
    name: "",
    tag: "",
    icon: "",
    childScreens: [],
  },
  users: {
    loading: false,
    data: [],
  },
  selectedRowKeys: null,
  selectedRows: null,
};

export const getToken = createAsyncThunk("getToken", async (body) => {
  const response = await client.post(Endpoints.IDENTITY.TOKEN, body);
  return response.data;
});

export const getUserInfo = createAsyncThunk(
  "getUserInfo",
  async (_, thunkAPI) => {
    const response = await client.get(Endpoints.IDENTITY.USER_INFO, {
      token: thunkAPI.getState().auth.token,
    });
    return response.data;
  }
);

export const getAuthorizedScreens = createAsyncThunk(
  "getAuthorizedScreens",
  async (_, thunkAPI) => {
    const response = await client.get(
      Endpoints.IDENTITY.USER_AUTHORIZED_SCREEN,
      {
        token: thunkAPI.getState().auth.token,
      }
    );
    return response.data;
  }
);

export const getUsers = createAsyncThunk("getUsers", async (_, thunkAPI) => {
  const response = await client.get(Endpoints.IDENTITY.GET_USERS, {
    token: thunkAPI.getState().auth.token,
  });
  return response.data;
});

export const getUsersPagination = createAsyncThunk("getUsersPagination", async (body, thunkAPI) => {
  const response = await client.get(Endpoints.IDENTITY.GET_USERS_PAGINATION
    +"?PageSize="+body.pageSize+"&Page="+body.page+"&sortByField="+body.sortByField+"&sortOrderAscending="
    +body.sortOrderAscending+"&searchTerm="+body.searchTerm+"&filterField="+body.filterField+"&filterValue="+body.filterValue, {
    token: thunkAPI.getState().auth.token,
  });
  return response.data;
});

const identitySlice = createSlice({
  name: "identity",
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    setSelectedRowKeys(state, action) {
      state.selectedRowKeys = action.payload;
    },
    setSelectedRows(state, action) {
      state.selectedRows = action.payload;
    },
    setUserNull(state, action) {
      state.token = "";
      state.userInfo = {};
      state.loading = "";
    },
  },
  extraReducers(builder) {
    builder.addCase(getToken.pending, (state, action) => {
      state.token = "";
      state.loading = true;
    });
    builder.addCase(getToken.fulfilled, (state, action) => {
      state.loading = false;
      state.token = action.payload !== undefined ? action.payload.payload : "";
    });
    builder.addCase(getToken.rejected, (state, action) => {
      state.token = "";
      state.loading = false;
    });

    builder.addCase(getUserInfo.pending, (state, action) => {});

    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      if (action.payload !== undefined)
        action.payload.payload.personelNo = parseInt(
          action.payload.payload.personelNo
        ).toString();

      state.userInfo =
        action.payload !== undefined ? action.payload.payload : {};
    });
    builder.addCase(getUserInfo.rejected, (state, action) => {});

    builder.addCase(getAuthorizedScreens.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getAuthorizedScreens.fulfilled, (state, action) => {
      state.authorizedScreens = action.payload.payload;
    });

    builder.addCase(getAuthorizedScreens.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getUsersPagination.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getUsersPagination.fulfilled, (state, action) => {
      state.loading = false;
      state.users.data =
        action.payload !== undefined ? action.payload.payload.data : [];
    });
    builder.addCase(getUsersPagination.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { setToken, setSelectedRowKeys, setSelectedRows, setUserNull } =
  identitySlice.actions;
export default identitySlice.reducer;
