export const success = (messageText,ref) => {
  ref.success({
    content: messageText,
    duration: 3, 
    style: {
      marginTop: 50,  
    },
  });
}

export const error = (messageText,ref) => {
    ref.error({
    content: messageText,
    duration: 3,  
    style: {
      marginTop: 50,  
    },
  });
}

export const info = (messageText,ref) => {
    ref.info({
    content: messageText,
    duration: 3,  
    style: {
      marginTop: 50, 
    },
  });
}

