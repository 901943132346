import React, { useState, useLayoutEffect, useRef } from "react";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import { success, error } from "../../../helpers/toast";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addCertificas, addFile, getCertificate, getCertificates } from "../../../store/slices/certificate";
import { Layout, Row, Col, Image, Button } from 'antd';
import './CertificateTwo.css';
import ReactToPrint from 'react-to-print';

const { Header, Content, Footer, Sider } = Layout;

const CertificateTemplateTwoPage = () => {
  const printRef = useRef();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  function handleNavigation(event, key) {
    event.preventDefault();
    navigate(`/${key}`);
  }

  useLayoutEffect(() => {
    if (searchParams.get("id")) {
      dispatch(getCertificate(searchParams.get("id")))
        .unwrap()
        .then((result) => {
          setData(result);
        });
    }
  }, [refresh]);

  const qrCodeUrl = `http://sertifika.akademitim.com/certificateTwo?id=${searchParams.get("id")}`;
  const signatureParts = data?.signature ? data.signature.split("-") : [];
  return (
    <Layout style={{ height: '100vh' }}>
      <Sider width="20%" style={{ background: '#f0f2f5', padding: '20px' }}>
        <ReactToPrint
          trigger={() => <Button type="primary" style={{ width: '100%' }}>Yazdır</Button>}
          content={() => printRef.current}
          pageStyle="@page { size: A4 landscape; margin: 0; } body { -webkit-print-color-adjust: exact; }"
        />
      </Sider>

      <Layout className="certificate-container-templatetwo" ref={printRef}>
        <div className="certificate-content-templatetwo">
          <Content className="certificate-main-content-templatetwo">
            <Row justify="center">
              <Col>
                <h1>{data?.certificateType}</h1>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <h2>Sayın {data?.studentName}</h2>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <h3>{data?.date}</h3>
                <h3>{data?.programName}</h3>
              </Col>
            </Row>
          </Content>
          <Footer className={`certificate-footer-templatetwo ${data?.logoTwo ? 'with-logo-two' : ''}`}>
            <Row justify="center" className={data?.logoTwo ? 'with-logo-two' : ''}>
              {data?.logoTwo ? (
                <>
                  <Col>
                    <Image className="image-with-maxwidth" src={data?.logoTwo} alt="Logo Two" preview={false} width={200} />
                  </Col>
                  <Col>
                    <QRCode value={qrCodeUrl} size={100} />
                  </Col>
                  <Col>
                    <Image  src={data?.logo} alt="Logo 1" preview={false} width={200} />
                  </Col>
                </>
              ) :
                data?.signature != null ? (
                  <>
                    <Col>
                      <QRCode value={qrCodeUrl} size={100} />
                    </Col>
                    <Col>
                      <Image src={data?.logo} alt="Logo 1" preview={false} width={200} />
                    </Col>

                    <Col>
                      {signatureParts.length > 1 ? (
                        signatureParts.map((part, index) => (
                          <p key={index} style={{ fontWeight: 'bold', fontSize: 'calc(1em + 4px)' }}>
                            {part}
                          </p>
                        ))
                      ) : (
                        <p style={{ fontWeight: 'bold', fontSize: 'calc(1em + 4px)' }}>
                          {data?.signature}
                        </p>
                      )}
                    </Col>

                  </>
                ) : (
                  <>
                    <Col>
                      <QRCode value={qrCodeUrl} size={100} />
                    </Col>
                    <Col>
                      <Image src={data?.logo} alt="Logo 1" preview={false} width={200} />
                    </Col>
                  </>
                )

              }
            </Row>
            {data?.logoTwo && (
              <Row className="logo-two-signature" justify="center" style={{ marginTop: '20px' }}>
                 <Col>
                      {signatureParts.length > 1 ? (
                        signatureParts.map((part, index) => (
                          <p key={index} style={{ fontWeight: 'bold', fontSize: 'calc(1em + 4px)' }}>
                            {part}
                          </p>
                        ))
                      ) : (
                        <p style={{ fontWeight: 'bold', fontSize: 'calc(1em + 4px)' }}>
                          {data?.signature}
                        </p>
                      )}
                    </Col>
              </Row>
            )}
          </Footer>

        </div>
      </Layout>
    </Layout>
  );
};

export default CertificateTemplateTwoPage;

