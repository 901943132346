import { createContext, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../helpers/api/apiCall';
import { setToken, setUser } from '../store/slices/auth';
import { Endpoints } from '../utils/Endpoints';

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const authState = useSelector((state) => state.auth);

    const verifyToken = (serviceToken) => {
        if (!serviceToken) {
            return false;
        }
        const decoded = jwtDecode(serviceToken);

        return decoded.exp > Date.now() / 1000;
    };

    const dispatch = useDispatch();
    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = authState.token;
                if (serviceToken && verifyToken(serviceToken)) {
                    const response = await client.get(Endpoints.IDENTITY.GET_USER_INFO, {
                        token: serviceToken,
                    });
                    const { success, payload } = response.data;
                    console.log(response)
                    if (success && payload.role ==="Admin") {
                        dispatch(setUser(payload))
                    } else {
                        dispatch(setUser({}))
                    }

                } else {

                }
            } catch (err) {
                console.error(err);

            }
        };

        init();
    }, [authState.token]);

    const login = async (userIdentifier, password) => {
        const response = await client.post(Endpoints.IDENTITY.TOKEN, { email:userIdentifier,password: password });
        const { success, payload } = response.data;
        if (success && payload) {
            dispatch(setToken(payload))
        } else {
            dispatch(setToken(''))
            dispatch(setUser({}))
        }
    };

    const logout = () => {
        dispatch(setToken(''))
        dispatch(setUser({}))
    };

    return (
        <JWTContext.Provider value={{ login, logout }}>{children}</JWTContext.Provider>
    );
};

export default JWTContext;